import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../../components/layout";

class TheCleansingProgramPart1Oxygen extends React.Component {
    render() {
        return (
            <Layout>
                <h1>The Cleansing Program - Part 1 Oxygen - Graham Ravey</h1>
                <sub>©TOGKA Traditional Okinawan Goju Ryu Karate Association, Chief Instructor Sensei Graham Ravey. This
                    document is the property of ©TOGKA and may not be reproduced without the
                    permission of the author Disclaimer: Information given here is for research and educational purposes
                    only and is not intended to prescribe treatment.</sub>

                <p>I wrote in my last article Ageing briefly about oxygenation and promised to expand later, so here it
                    goes!</p>

                <p>First let's clarify what a human being basically is. In one sentence (very scientifically) we are
                    super oxygenating, mineral processing machines, each and every one of our cells should be full of
                    oxygen and 87 various minerals. The oxygen is fuel for the cell to process these minerals in many
                    patterns so we can blink, laugh, cry, walk, etc, in
                    other words mineral combinations are responsible for every function of the body and having enough
                    fuel (oxygen) is as important.</p>

                <p>Even if we have the minerals the cell can't process them without oxygen, so like hand in glove, they
                    go together, but talk about minerals will come later. Let's talk more about the Fuel.</p>

                <p>So if your cells are super oxygenated they fight off 100% of all Microbes, viruses, bacteria and
                    fungi etc. that attack our body (daily) much quicker, and in some cases prevent it happening. There
                    are I believe over 300 different types of microbe to plague us, more so now with pets around the
                    household. These parasites freely use our blood stream to commute around the body, some take up
                    residence at some locations and that can be nasty. If you could see these unwanted squatters under a
                    microscope, you would know what I mean, (see Cure for all Cancers, written by Hilda Regehr Clark
                    PHD, ND page 5 for close ups and more info).</p>

                <p>One thing all these microbes have in common is the fact that they are Anaerobic Low Oxygen Breathers.
                    They don't like high oxygen places and will die, but viruses are rapid breeders, they eject eggs
                    when life is threatened and can retreat into Deep Lymph Tissue. Areas were blood flow is at its
                    minimum and so is oxygen. There they breed up and when in numbers attack the blood system again,
                    usually when your bio-rhythms are low.</p>

                <p>I have a machine called a Magnetic Pulsar to clean the deep lymph tissue areas of such scum. I will
                    talk about this item also as the cleansing unfolds. We have established then that we need oxygen
                    otherwise we fall foul to disease. We are lucky if our air contains 27% oxygen these days and in
                    busy cities only 20%. Air bubbles trapped in amber sap from trees dating back hundreds of years has
                    been analysed for oxygen content and was found to contain no less than 45%. We are running nearly on
                    half strength fuel; no wonder the numerous anaerobic germs are having a field day in our bodies.</p>

                <p><strong>What can we do?</strong><br/>
                    This is published in several articles and I quote form Ed McCabe's book. "The F.D.A (Federal Drug
                    Administration (US) . or A.D.A. (Australian Drug Administration) would like to keep it a secret from
                    the public as long as they can, for 2 reasons. Oxygen is cheap and therefore the multi-million drug
                    cartel system in place right now would crumble. It is well known that the top share holders in
                    pharmaceutical supplies and the F.D.A board of directors are the same people. Secondly the
                    government do not want people over 50 years old to be healthy and have extended lives (especially
                    social benefit recipients), so we must be responsible for our own health and supplement our oxygen
                    intake by buying it."</p>

                <p>Does it sound so strange to buy your oxygen when we have been buying water for some time now? I have
                    said so far that we don't have a large enough percentage of oxygen in our air, but I did not say
                    why? In one sentence, it is due to the rapid evolvement of humans. In nature oxygen is made by the
                    sun rays passing through the ozone layer (O3). On the way through they through they pick up an O
                    molecule from the ozone layer, this O molecule travels down the ray of light until it reaches a
                    cloud (H2O) and deposits the molecule which then makes (H2O2), basically known to us as hydrogen
                    peroxide.</p>

                <p>I don't like this word as it contours up thoughts of bleach, I would rather call it liquid Ozone (The
                    ozone layer comes in contact with water). Liquid ozone is then poured onto earth again by nature at
                    the next cloud burst. Everything in theory is then given super oxygen. I said in theory because the
                    fact is, our air is so polluted by jet engine discharge, factory and car emissions etc that by the
                    time the liquid ozone (rain) has reached earth it has only 50% of its original strength, the rest
                    has been used on cleaning the atmosphere on the way down. The plants we eat are already low in
                    oxygen, then we boil most of what's left out of them before we consume, so we definitely are not
                    getting enough H2O2.</p>

                <p>I have been taking liquid ozone now for over 7 years as well as doing other protocols that make up
                    the cleansing package. I feel great and notice that if a virus gets hold of me it seems to not go
                    the distance and I get better quicker. I get my oxygen from a chemical company as Hydrogen Peroxide
                    35% F.G. (Food Grade), it is not available over the counter anywhere else. Even a pharmacy only
                    stocks a 3% antiseptic or 6% hair bleach and these are not pure, they have stabilisers in them for a
                    €œshelf life. This 35% food grade is the only one that is used for consumption in a 1:200 parts
                    water solution recipe.</p>

                <p>Do not mention you are going to drink it for health as they will not sell it to you, the F.D.A. makes
                    sure of that, I told them I have Hamburger stalls and I need the Food Grade for washing every
                    utensil I use. Even the F.D.A. recognizes the fact that hydrogen peroxide at 3% antiseptic solution
                    will kill 100% of all microbes. <br/> It comes in a 20 or 25 litre container, which is too much for
                    one person to use up so try and get some like minded people to go in with you. Maybe a whole dojo
                    could invest. I will not pretend to you that when taken orally it tastes nice, there is a distinct
                    bleach taste for a minute or two before the oxygen disperses through the body and on an empty
                    stomach this is more evident. You get used to it and for the little discomfort of taste you reap so
                    many healthy rewards. I assume from here on that you have acquired the H.P. 35% F.G, but before I
                    give you some recipes I will go through the protocols of H2O2.</p>

                <ol>
                    <li>Don't drink or spray it in the body at night, it will give you energy and you can't sleep.</li>

                    <li> It should be drunk 3 hours at least after food or 1 hour before. The best time I think is in
                        the morning 1 hour before breakfast, if this is impossible how about<br/>
                        4PM if you last ate at 1PM and your dinner is about 5PM, anyway this is your decision. The
                        reason you don't want food in your stomach is because oxygen will fight the acid breaking down
                        the food and by the time it gets to the cells is not at it's strongest.
                    </li>

                    <li> Take oxygen every day if you feel flu like symptoms for a week or so, don't worry e.g. strong
                        urine smell, dizzy headaches etc, this is your body detoxifying from the oxygen and is not a bad
                        thing. Cut back to 3 times a week if you like until it goes. Basically if you are a sick person
                        you could feel more detoxifying effect than a person using oxygen as a maintenance program.
                    </li>

                    <li> Never drink 35% F.G. pure! It will kill you. 35% H.P. is like battery acid in it's 35% state,
                        it is very corrosive and toxic but not inflammable. Keep in a cool place and in its 35% state
                        until used. Because it is very pure and has no preservatives, it has low shelf life. Dilute only
                        as needed and use gloves and eye wear when diluting. If 35% comes into contact with skin it will
                        send the area white for a while and a prickly sensation will be experienced, wash in water
                        immediately.
                    </li>

                </ol>

                <p><strong>Recipes</strong><br/>
                    Oral Dose 10 drops of 35% F.G. H.P. in a 8oz glass of water (1:200) to be drunk daily.
                    3% Antiseptic Dilute to a 1:12 solution, 12 parts water and 1 part 35% F.G. I use a garden spray
                    bottle; they usually have a scale on the side and makes it easier for dilution. A 3% antiseptic is
                    used on cuts or for replacing the acid mantel on the body after a shower. For gargling (never
                    swallow) as a mouth wash. Sometimes swish a bit of 3% under the tongue for a minute, it will put
                    oxygen in the blood stream via the thin membrane located there. It can be used around the kitchen
                    and toilet as one of the most effective bacteria killers there is, it is used in hospitals as such.
                    I spray some on my body before a training session for a bit of an oxygen boost. Put 2-3 drops of 35%
                    F.G. in a 2 litre container of tap water. It will kill bacteria and oxygenate the tap water; it
                    gives a waterfall like flavour. Don't think that your tap water is clean because you put it through
                    a filter, filters only separate metal pollutants (toxics) from the water, not bacteria. 10 bacteria
                    shoulder to shoulder as to speak could fit through one hole in a filter, that's how small these
                    microbes are. 1 drop of 35 F.G. in the water used for spraying or boiling vegetables will put some
                    oxygen through them, don€™t boil them for too long though. Use 3% H.P. instead of toothpaste. If you
                    look at toothpaste, the only active ingredient in it is oxygen. It bleaches teeth white and kills
                    germs (gingivitis). Spray some in the mouth and brush as usual; it will foam up like toothpaste. The
                    foaming action of peroxide only occurs when it contacts bacteria, there are over 3000 articles
                    written on H.P. for health. I encourage you to download some. I have given you the two main recipes
                    for the cleansing program that I use for health and longevity against microbes, but there are some
                    more for all kinds of things.</p>

                <p><strong>In the garden</strong> 16 drops of 35 to a quart of water will make flowers produce growth
                    and yield more.<br/>
                    <strong>As a facial</strong> 3% on a cotton ball
                    <strong>Vegetable soak</strong> add ½ cup of 35% to a sink full of water, soak vegetables for a
                    minute in it, they will last longer in fridge.<br/>
                    <strong>Eye bath</strong> spray 3% once in the eye bath, fill eye bath with luke warm water, soak
                    eye. A warm but comfortable sensation should be felt.<br/>
                    <strong>Alternate bath</strong> ½ cup 35% H.P. plus ½ cup of sea salt, mix into hot bath water and
                    soak body.<br/>
                    <strong>Athletes foot</strong> soak in 3% solution every night till better.</p>

                <p>As a martial artist I encourage you to try oxygen and see if it benefits your training as I feel it
                    does mine.</p>

                <p>In part 2 of the cleansing program I will cover the benefits of using an electrical blood cleaner in
                    collaboration with oxygen.</p>
                <p>This is the second tool to use against microbes in your bodies.</p>

                <p><strong>Sensei Graham Ravey</strong></p>
            </Layout>);
    }
}

export default TheCleansingProgramPart1Oxygen;
